<template>
    <div class="balance-transfer-modal">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    Previsão de Recorrência
                </p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <b-loading :is-full-page="false" v-model="isLoading" />

                <section v-if="!hasAcceptedPreview" class="initial-message">
                    <p class="message-title">
                        Aviso Importante!
                    </p>
                    <p class="message-text">
                        Antes de continuar, leia com atenção as informações abaixo.
                    </p>
                    <p class="message-text">
                        Os horários disponíveis ficarão reservados de outras previsões por até <strong>10 minutos</strong> enquanto você analisa esta previsão.
                    </p>
                    <p class="message-text">
                        Durante esse período, novos agendamentos podem ser feitos para os horários disponíveis. 
                        Caso isso aconteça, esses horários serão movidos para a lista de ocupados ao confirmar a previsão. 
                        Nesse caso, será necessário reconfirmar a previsão, caso queira prosseguir com o agendamento.
                    </p>
                    <p class="message-footer">
                        Clique em <strong>PROSSEGUIR</strong> para continuar e visualizar os horários.
                    </p>

                    <b-button
                        class="is-dark"
                        expanded
                        :loading="isLoading"
                        @click="proceedToPreview"
                    >
                        PROSSEGUIR
                    </b-button>
                </section> 

                <section v-else>
                    <h2 class="title_section">
                    Horários Disponíveis
                    </h2>
                    <hr>
                    <article v-for="(data, key) in formattedPreviewSchedule" v-bind:key="key">
                        <div class="card_preview_schedule">
                            <div class="card-body">
                                <div>
                                    <p> {{ data.start }} </p>
                                    <p> {{ data.end }} </p>
                                </div>
                                <div class="preview date">
                                    <span class="date-grid-span2"> 
                                        <b-icon icon="calendar-week"></b-icon>
                                        <span> {{ data.weekDay }} </span>
                                    </span>
                                    <span class="date-grid-span">
                                        <span>|</span><span> {{ data.date }} </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </article>
                    <hr>
                    <h2 class="title_section">Horários Indisponíveis</h2>
                    <hr>
                    <article v-for="(data, key) in formattedConflictSchedule" v-bind:key="key">
                        <div class="card_preview_schedule">
                            <div class="card-body">
                                <div>
                                    <p> {{ data.start }} </p>
                                    <p> {{ data.end }} </p>
                                </div>
                                <div class="conflict date"> 
                                    <span class="date-grid-span2"> 
                                        <b-icon icon="calendar-week"></b-icon>
                                        <span> {{ data.weekDay }} </span>
                                    </span>
                                    <span class="date-grid-span"> 
                                        <span>|</span><span> {{ data.date }} </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </article>
                </section>
            </section>

            <footer v-if="hasAcceptedPreview" class="modal-card-foot">
                <b-button
                    class="is-dark"
                    expanded
                    :loading="isSaving"
                    @click="confirmPreview"
                >
                    Cadastrar Horários Disponíveis
                </b-button>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => ({})
        },
        createRecurrenceSchedule: {
                type: Function,
                default: () => {},
        },
        isLoading: Boolean
    },
    data() {
        return {
            dataLocal: { ...this.data },
            hasAcceptedPreview: false
        };
    },
    computed: {
        formattedPreviewSchedule() {
            return this.formatSchedules(this.dataLocal.preview_schedule);
        },
        formattedConflictSchedule() {
            return this.formatSchedules(this.dataLocal.conflicts);
        }
    },
    methods: {
        formatSchedules(schedules) {
            return schedules.map(schedule => {
                const startDate = new Date(schedule.start.replace(' ', 'T'));
                const endDate = new Date(schedule.end.replace(' ', 'T'));

                return {
                    weekDay: startDate.toLocaleString('pt-BR', {
                        weekday: 'long',
                    }),
                    date: startDate.toLocaleString('pt-BR', {
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit',
                    }),
                    start: startDate.toLocaleString('pt-BR', {
                        hour: '2-digit',
                        minute: '2-digit',
                    }),
                    end: endDate.toLocaleString('pt-BR', {
                        hour: '2-digit',
                        minute: '2-digit',
                    })
                };
            });

        },
        proceedToPreview() {
            this.hasAcceptedPreview = true;
        },
        confirmPreview() {
            this.isLoading = true;
            this.createRecurrenceSchedule(this.dataLocal)
                .then(() => {
                    this.$emit('close');
                })
                .catch((data) => {
                    this.handleConflicts(data.conflicts);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        handleConflicts(conflicts) {
            conflicts?.forEach(conflict => {
                if (this.isNewConflict(conflict)) {
                    this.addConflictToLocalData(conflict);
                }
                this.removeConflictFromPreviewSchedule(conflict);
            });
        },
        isNewConflict(conflict) {
            return !this.dataLocal.conflicts.some(c => c.start === conflict.start && c.end === conflict.end);
        },
        addConflictToLocalData(conflict) {
            this.dataLocal.conflicts.push(conflict);
        },
        removeConflictFromPreviewSchedule(conflict) {
            this.dataLocal.preview_schedule = this.dataLocal.preview_schedule.filter(c => c.start !== conflict.start && c.end !== conflict.end);
        }
    },
    watch: {
        data(newValue) {
            this.dataLocal = { ...newValue };
        }
    }
}
</script>

<style lang="scss" scoped>
    .initial-message {
        text-align: center;
        padding: 2rem;
        background-color: #f9f9f9;
        border-radius: 0.5rem;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        .message-title {
            font-size: 1.5rem;
            font-weight: bold;
            color: var(--color-primary);
            margin-bottom: 2rem;
        }
        .message-text {
            text-align: justify;
            font-size: 1rem;
            color: var(--text-color-medium);
            margin-bottom: 1rem;
            line-height: 1.5;
        }
        .message-footer {
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 2rem;
        }
    }
    .card_preview_schedule {
        font-weight:100;
        color: var(--text-color-medium);
        
        .card-body {
            display:flex;
            gap: 1rem;
            padding: .2rem;
            text-align: left;
        }
        .date {
            display:grid;
            grid-template-columns: repeat(4, 1fr); 
            justify-content: space-around;
            align-items: center;
            width: 100%;
            align-items: center;
            border-radius: .3rem;
            color: white;
            font-weight:600;

            .date-grid-span {
                grid-column: span 2;
                display: flex;
                justify-content: left;
                gap: 2rem
            };

            .date-grid-span2 {
                display: flex;
                justify-content: left;
                padding-left: 2rem;
                gap: 1rem;
                grid-column: span 2;
            };
        }
        .preview {
            background-color: var(--color-secondary);
        }
        .conflict {
            background-color: var(--color-primary);
        }
    }
    .title_section {
        font-size: 1.5rem;
        text-align: center;
        color: var(--text-color);    
    }
</style>

