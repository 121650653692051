<template>
  <div class="modal-card" style="width: auto">
    <b-loading :active.sync="isLoading" :is-full-page="false" />

    <header class="modal-card-head">
      <p class="modal-card-title">Novo Horário</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <b-field
        label="Tipo da Agenda"
      >
        <b-collapse 
          class="card" 
          animation="slide" 
          aria-id="contentIdForA11y3"
        >
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              aria-controls="contentIdForA11y3"
              :aria-expanded="props.open"
            >
              <p class="card-header-title">
                Selecione os Planos
              </p>
              <a class="card-header-icon">
                <b-icon
                  :icon="props.open ? 'menu-down' : 'menu-up'"
                >
                </b-icon>
              </a>
            </div>
          </template>

          <div class="card-content collapse-content">
            <b-field>
              <b-checkbox
                type="is-secondary"
                v-model="isAllSelected"
                @input="toggleSelectAll"
              >
                Todos
              </b-checkbox>
            </b-field>

            <!-- The "Particular" option needs to be a only checkbox, because it is not treated as a health plan -->
            <b-field>
              <b-checkbox
                type="is-secondary"
                v-model="typeOfSchedule"
                @input="alertValue"
                :native-value="SCHEDULES_TYPES.PARTICULAR"
              >
                Particular
              </b-checkbox>
            </b-field>

            <b-field v-for="plan in healthPlans" :key="plan.id">
              <b-checkbox
                type="is-secondary"
                v-model="selectedHealthPlans"
                :native-value="plan.id"
              >
                {{ plan.name }}
              </b-checkbox>
            </b-field>
          </div>
        </b-collapse>
      </b-field>

      <div class="row mb-4">
        <div class="col-6 mt-3 mb-3">
          <weekdays-selector v-model="days"></weekdays-selector>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12 mt-3 mb-3">
          <time-selector v-model="standard_time"></time-selector>
        </div>
      </div>
      
      <div class="row">
        <div class="col-6">
          <app-input
            type="tel"
            is-required
            label="Início"
            v-mask="'##:##'"
            placeholder="Informe o horário inicial"
            v-model="start_hour"
            :errors="errors.start_hour"
          ></app-input>
        </div>
        <div class="col-6">
          <app-input
            is-required
            label="Fim"
            v-mask="'##:##'"
            placeholder="Informe o horário final"
            v-model="end_hour"
            :errors="errors.end_hour"
          ></app-input>

          <div v-if="endShowError" id="schedule-standard-time-error">
            Atenção! Escolha um intervalo de início e fim onde se encaixe
            consultas de {{ standard_time }} minutos
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div :class="canShowServiceLocations ? 'col-6' : 'col-12'">
          <b-field label="Tipo da consulta" class="required">
            <b-select
              v-model="type"
              placeholder="Selecione o tipo"
              :disabled="disableTypes"
              expanded
            >
              <option
                v-for="(type, idx) in types"
                :key="idx"
                :value="type.value"
              >
                {{ type.label }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div v-if="canShowServiceLocations" class="col-6">
          <b-field
            label="Local de atendimento"
            class="required"
            :type="errors && errors.location_id ? 'is-danger' : ''"
            :message="errors && errors.location_id ? errors.location_id[0] : ''"
          >
            <b-select
              v-model="location"
              placeholder="Selecione o local"
              :disabled="disableLocations"
              expanded
            >
              <option
                v-for="(location, idx) in locations"
                :key="idx"
                :value="location.id"
              >
                {{ location.title }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>

      <!-- <div class="row mt-4">
        <div class="col-6">
          <b-field
            label="Período Inicial"
            :type="errors && errors.date_start ? 'is-danger' : ''"
            :message="errors && errors.date_start ? errors.date_start[0] : ''"
          >
            <b-datepicker
              v-model="date_start"
              :show-week-number="false"
              :locale="'pt-BR'"
              placeholder="Início"
              icon="calendar"
              :max-date="date_end"
              trap-focus
              inline
            >
            </b-datepicker>
          </b-field>
        </div>
        <div class="col-6">
          <b-field
            label="Período Final"
            :type="errors && errors.date_end ? 'is-danger' : ''"
            :message="errors && errors.date_end ? errors.date_end[0] : ''"
          >
            <b-datepicker
              v-model="date_end"
              :show-week-number="false"
              :locale="'pt-BR'"
              placeholder="Fim"
              icon="calendar-alt"
              :min-date="date_start"
              trap-focus
              inline
            >
            </b-datepicker>
          </b-field>
        </div>
      </div> -->
      
    </section>
    <footer class="modal-card-foot is-right">
      <b-button @click="$emit('close')">Fechar</b-button>
      <b-button
        @click="save()"
        :loading="isSaving"
        :disabled="isDisabled || isSaving"
        type="is-primary"
      >
        Salvar
      </b-button>
    </footer>
  </div>
</template>

<script>
import SchedulesService from '@/services/schedules.service';

import ClinicsService from '@/services/clinics.service';
import WeekdaysSelector from '@/components/WeekdaysSelector';
import ServiceLocations from '@/services/service-locations.service';
import HealthPlansService from '@/services/health-plans.service';

import { mapGetters } from 'vuex';
import UserTypesEnum from '../enums/UserTypesEnum';
import ScheduleTypes from '../enums/ScheduleTypesEnum';
import ScheduleStandartTimeSelector from '../components/ScheduleStandartTimeSelector.vue';

export default {
  components: {
    WeekdaysSelector,
    'time-selector': ScheduleStandartTimeSelector,
  },
  props: {
    timeId: Number,
    doctorId: Number,
  },
  data: () => ({
    isSaving: false,
    start_hour: null,
    end_hour: null,
    startError: '',
    endError: '',
    type: null,
    days: [],
    timeRangeError: null,
    endShowError: false,
    date_start: null,
    date_end: null,
    standard_time: null,
    errors: {
      start: [],
      end: [],
    },
    types: [],
    locations: [],
    location: null,
    USER_TYPES: UserTypesEnum,
    SCHEDULES_TYPES: ScheduleTypes,
    locationsAvailableForDoctors: false,
    healthPlans: [],
    selectedHealthPlans: [],
    isLoading: false,
    typeOfSchedule: []
  }),
  computed: {
    isDisabled() {
      return !this.start_hour || !this.end_hour || !this.days.length || (!this.selectedHealthPlans.length && !this.typeOfSchedule.length);
    },
    canSetServiceLocation() {
      return (
        this.userType !== this.USER_TYPES.DOCTOR ||
        (this.userType == this.USER_TYPES.DOCTOR &&
          this.locationsAvailableForDoctors)
      );
    },
    canShowServiceLocations() {
      return this.type != null && this.type != 1 && this.canSetServiceLocation;
    },
    disableTypes() {
      return this.types.length === 1;
    },
    disableLocations() {
      return this.locations.length === 1;
    },
    canShowHealthPlans() {
      return this.healthPlans.length;
    },
    isAllSelected() {
      return (
        this.selectedHealthPlans.length === this.healthPlans.length && this.typeOfSchedule.length > 0
      );
    },
    ...mapGetters(['userId', 'clinicId', 'userType', 'doctorType']),
  },
  watch: {
    start_hour(newValue) {
      if (!newValue) return;

      const [hours, minutes] = newValue.split(':');
      if (!hours || !minutes) return;

      if (Number(hours) < 0 || Number(hours) > 23)
        this.errors.start_hour = ['Horário inválido!'];
      else if (Number(minutes) < 0 || Number(minutes) > 59)
        this.errors.start_hour = ['Horário inválido!'];
      else this.errors.start = [];
    },
    end(newValue) {
      if (!newValue) return;

      const [hours, minutes] = newValue.split(':');

      if (!hours || !minutes) return;

      if (this.start_hour > newValue) {
        this.errors.end_hour = ['Horário não pode ser menor que o de início!'];
      } else if (Number(hours) < 0 || Number(hours) > 23)
        this.errors.end_hour = ['Horário inválido!'];
      else if (Number(minutes) < 0 || Number(minutes) > 59)
        this.errors.end_hour = ['Horário inválido!'];
      else this.errors.end_hour = [];
    },
    type(newValue) {
      if (newValue !== 0) this.selectedHealthPlan = null;
    },
  },
  methods: {
    toggleSelectAll() {
      if (this.isAllSelected) {
        this.selectedHealthPlans = []
        this.typeOfSchedule = [];
      } else {
        this.selectedHealthPlans = this.healthPlans.map((plan) => plan.id);
        this.typeOfSchedule.push(this.SCHEDULES_TYPES.PARTICULAR);
      }
    },
    save() {
      this.isSaving = true;

      const {
        start_hour,
        end_hour,
        days,
        type,
        date_start,
        date_end,
        standard_time
      } = this;

      // if the user has selected a healt plan, we need to save the convenio schedules
      if (
        this.selectedHealthPlans.length > 0 && 
        !this.typeOfSchedule.includes(this.SCHEDULES_TYPES.CONVENIO)
      ) {
        this.typeOfSchedule.push(this.SCHEDULES_TYPES.CONVENIO)
      }

      const promises = [];

      for(let typeSchedule of this.typeOfSchedule) {
        // firts save the particular schedules, then create for the convenio schedules
        for(let weekday of days) {
          promises.push(SchedulesService.storeAvailability({
            start_hour,
            end_hour,
            type,
            weekday,
            date_start,
            date_end,
            standard_time,
            user_id: this.userId,
            doctor_id: this.doctorId,
            location_id: this.location,
            health_plan_id: typeSchedule === this.SCHEDULES_TYPES.CONVENIO ? this.selectedHealthPlans : [],
            type_of_schedule: typeSchedule,
          }))      
        }
      }

      Promise.all(promises)
        .then((responses) => {
          const times = responses.map(({ data }) => data);
          this.$buefy.snackbar.open('Horários cadastrados com sucesso!');
          this.$emit('close', times);
        })
        .catch(({ response }) => {
          const { status, data } = response;

          if (status == 422) {
            this.errors = data.message;
          }

          this.$buefy.snackbar.open(
            'Erro ao cadastrar um ou mais horários. Verifique se não há sobreposição.'
          );
        })
        .finally(() => (this.isSaving = false));
    },
    canSetStandartTime() {
      return this.start_hour && this.end_hour;
    },
    async loadTypes() {
      await SchedulesService.getAvailableScheduleTypes().then(({ data }) => {
        this.types = data;

        if (this.types.length === 1) {
          this.type = this.types[0].value;
        }
      });
    },
    async loadServiceLocations() {
      await ServiceLocations.get({ clinic_id: this.clinicId }).then(
        ({ data }) => {
          this.locations = data.map((location) => ({
            title: location.title,
            id: location.address_id,
            location_id: location.id,
          }));

          if (this.locations.length === 1) {
            this.location = this.locations[0].id;
          }
        }
      );
    },

    loadDoctorsServiceLocations() {
      const params = {
        doctor_id: this.doctorId,
      };

      ServiceLocations.getDoctorLocations(this.clinicId, params)
        .then(({ data }) => {
          this.locations = this.locations.filter((location) =>
            data.includes(location.location_id)
          );

          if (this.locations.length === 1) {
            this.location = this.locations[0].id;
          }
        })
        .finally();
    },
    async checkAvailabilityOfServiceLocations() {
      await ClinicsService.locationsAvailableForDoctors(this.clinicId)
        .then(({ data }) => {
          const { available_for_doctors } = data;
          this.locationsAvailableForDoctors = available_for_doctors;
        })
        .finally(() => {
          if (
            this.userType == this.USER_TYPES.DOCTOR &&
            this.canSetServiceLocation
          ) {
            this.loadDoctorsServiceLocations();
          }
        });
    },
    async loadHealthPlans() {
      await HealthPlansService.get().then(({ data }) => {
        this.healthPlans = data;
      });
    },
    loadAllPromises() {
      this.isLoading = true;

      const promises = [
        this.loadTypes(),
        this.loadHealthPlans(),
        this.loadServiceLocations(),
        this.checkAvailabilityOfServiceLocations(),
      ];

      Promise.all(promises).finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.loadAllPromises();
  },
};
</script>
<style lang="scss" scoped>
#schedule-standard-time-error {
  color: red;
}
.custom-b-select {
    width: 100%; /* Define o tamanho desejado */
}
.collapse-content {
  height: 150px;
  overflow: auto;
}
</style>
