<template>
    <section class="new-schedule-view">
      <div class="new-schedule-view__details">
        <div class="new-schedule-view__details__header">
          <div v-if="step === 0">
            <h1>Nova sessão:</h1>
            <h2 v-if="!schedule.fit">
              {{ attributes.start | date('DD/MM/YYYY HH:mm') }}
            </h2>
            <h2 v-else>{{ scheduleFitDate | date('DD/MM/YYYY HH:mm') }}</h2>
          </div>
  
          <div v-else>
            <h1>Novo atendimento:</h1>
            <h2>Cadastro de paciente</h2>
          </div>
  
          <div @click="() => (step === 0 ? $emit('close') : (step = 0))">
            <b-icon :icon="step === 0 ? 'times' : 'arrow-left'"></b-icon>
          </div>
        </div>
  
        <div v-if="step === 0" class="new-schedule-view__details__form custom-scroll">
          <form>
            <div class="new-schedule-view__details__form__input patient">
              <app-autocomplete 
                  is-required 
                  field="name" 
                  label="Adicionar Paciente" 
                  ref="patientSelector" 
                  :setter="patientSetter"
                  :getter="patientsGetter" 
                  :errors="errors.patient_id" 
                  v-model="schedule.patient_id"
                  placeholder="Nome ou o CPF do paciente">
              </app-autocomplete>
              
              <b-tooltip label="Adicionar Paciente" position="is-left">
                <b-button @click="addPatient" type="is-primary" class="col-1 mt-4">
                    <b-icon icon="plus" size="is-small"></b-icon>
                </b-button>
              </b-tooltip>

              <b-tooltip label="Cadastrar novo paciente" position="is-left">
                  <b-button @click="() => (step = 1)" type="is-text" icon-left="user-plus" class="new-patient-button" />
              </b-tooltip>
            </div>

            <div v-if="selectedPatients.length >= 1" class="new-schedule-view__details__selected-patients mt-2">
              <h3>Pacientes Adicionados a sessão:</h3>
              <ul>
                <li
                  v-for="patient in selectedPatients"
                  :key="patient.id"
                  class="new-schedule-view__details__selected-patients__patient-item"
                >
                  {{ patient.name }}
                  <b-button
                    @click="removePatient(patient.id)"
                    type="is-text"
                    icon-left="trash"
                  />
                </li>
              </ul>
            </div> 
            
            <div class="new-schedule-view__details__form__input">
              <app-input type="textarea" :maxLength="280" :hasCounter="true" placeholder="..." tooltipPosition="is-bottom"
                label="Instruções ao paciente" v-model="schedule.patient_instructions"
                tooltipLabel="Esta mensagem será enviada automaticamente ao paciente.">
              </app-input>
            </div>
  
            <div v-if="activeVoucher" class="new-schedule-view__details__form__input">
              <div>
                <b-field label="Retorno">
                  <b-switch v-model="schedule.has_voucher"></b-switch>
                </b-field>
              </div>
              <div v-if="schedule.has_voucher" class="mt-2">
                <b-field label="Voucher a ser utilizado:">
                  <p>
                    {{ activeVoucher.code }} -
                    <b>venc. {{ activeVoucher.due_date | date }}</b>
                  </p>
                </b-field>
              </div>
            </div>

            <div class="new-schedule-view__details__form__input">
            <b-field label="Recorrência">
              <b-switch v-model="is_recurrence"></b-switch>
            </b-field>

            <div v-if="is_recurrence">
              <b-field class="required">
                <app-input is-required v-model="frequency_interval" label="Frequência em dias" placeholder="Ex: 7"></app-input>
              </b-field>

              <b-field class="required">
                <app-input is-required v-model="duration_in_days" label="Duração em dias" placeholder="Ex: 90"></app-input>
              </b-field>
            </div>
          </div>
          </form>
        </div>
  
        <div v-if="step === 1" class="new-schedule-view__details__form custom-scroll">
          <div class="new-schedule-view__details__form__input">
            <app-input is-required label="Nome" :errors="errors.name" v-model="patient.name"
              placeholder="Ex: João campos" />
          </div>
  
          <div class="new-schedule-view__details__form__input">
            <app-input is-required label="CPF" :mask="MASKS.cpf" v-model="patient.cpf" :errors="errors.cpf"
              placeholder="111.222.333-55" />
          </div>
          <div class="new-schedule-view__details__form__input">
            <app-input type="tel" is-required label="Celular" :errors="errors.phone" v-model="patient.phone"
              v-mask="'(##) #####-####'" placeholder="(99) 99999-9999"></app-input>
          </div>
  
          <div class="new-schedule-view__details__form__input">
            <b-checkbox style="margin-top: 10px" v-model="patient.has_guardian">
              Menor de idade ou incapaz?
            </b-checkbox>
          </div>
  
          <div v-if="patient.has_guardian">
            <div class="new-schedule-view__details__form__input">
              <app-input is-required type="text" label="Nome do responsável" placeholder="Ex: Márcio campos"
                :errors="errors.guardian_name" v-model="patient.guardian_name" />
            </div>
  
            <div class="new-schedule-view__details__form__input">
              <app-input is-required type="tel" v-mask="'###.###.###-##'" label="CPF do responsável"
                placeholder="111.222.333-55" :errors="errors.guardian_document" v-model="patient.guardian_document" />
            </div>
  
            <div class="new-schedule-view__details__form__input">
              <b-field class="required" label="Data de nascimento do responsável"
                :type="errors && errors.guardian_birthdate ? 'is-danger' : ''" :message="errors && errors.guardian_birthdate
                  ? errors.guardian_birthdate
                  : ''
                  ">
                <b-datepicker :locale="'pt-BR'" position="is-top-right" :years-range="[-100, 0]"
                  placeholder="Selecionar data" :date-formatter="bDateFormatter" v-model="patient.guardian_birthdate">
                </b-datepicker>
              </b-field>
            </div>
          </div>
        </div>
  
        <div>
          <div class="new-schedule-view__details__save-schedule">
            <b-button expanded type="is-dark" :loading="isSaving" @click="onSubmit" :disabled="!isSubmitButtonEnabled">
              {{ step === 0 ? 'Cadastrar atendimento' : 'Cadastrar paciente' }}
            </b-button>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import ServiceLocations from '@/services/service-locations.service';
  import SchedulesService from '@/services/schedules.service';
  import SessionService from '../../services/session.service';
  import ProceduresService from '@/services/procedures.service';
  import PatientsService from '@/services/patients.service';
  import VouchersService from '@/services/vouchers.service';
  
  import AppAutocomplete from '@/components/inputs/Autocomplete.vue';
  
  import { mapGetters } from 'vuex';
  
  import BuefyMixin from '@/mixins/BuefyMixin';
  import MASKS from '@/constants/masks.constant';
  import _debounce from 'lodash/debounce';
  import moment from 'moment';
  import LinkPaymentOptionVue from '../../modals/LinkPaymentOption.vue';
  
  export default {
    name: 'new-session',
    components: { AppAutocomplete },
    mixins: [BuefyMixin],
    props: {
      attributes: {
        type: Object,
        default: null,
      },
    },
    data: () => ({
      step: 0,
      errors: {},
      isSaving: false,
      activeVoucher: null,
      procedureIsLoading: false,
      availableValues: [],
      availableLocations: [],
      availableProcedures: [],
      patient: {
        name: null,
        cpf: null,
        phone: null,
        has_guardian: false,
        guardian_name: null,
        guardian_document: null,
        guardian_birthdate: null,
      },
      selectedPatients: [],
      schedule: {},
      MASKS: MASKS,
      scheduleFitDate: null,
      is_recurrence: false,
      frequency_interval: null,
      duration_in_days: null,
    }),
    watch: {
      step: _debounce(function () {
        if (this.step === 0 && this.schedule.patient_id) {
          this.$refs.patientSelector.loadResourceById(this.schedule.patient_id);
        }
        this.patient = {};
      }, 800),
      attributes() {
        this.defaultScheduleAttributes();
        this.loadAvailableScheduleValues();
      },
      scheduleFitDate() {
        this.schedule.date = this.scheduleFitDate;
      },
      canCheckVoucher(newValue) {
        if (newValue) {
          this.checkActiveVoucher();
        }
      },
      'attributes.procedure_id'() {
        this.updateSchedule();
      },
    },
    computed: {
      canCheckVoucher() {
        return this.schedule.patient_id && this.schedule.doctor_id;
      },
      selectedEventAddress() {
        const location = this.availableLocations[0];
  
        if (!location) return null;
  
        const { address } = location;
  
        return `${address.street}, ${address.number} - ${address.cep}, ${address.city.name_with_state}`;
      },
      isSubmitPatientButtonEnabled() {
        const {
          name,
          cpf,
          phone,
          has_guardian,
          guardian_name,
          guardian_document,
          guardian_birthdate,
        } = this.patient;
  
        if (!name || !cpf || !phone || phone.length < 15) {
          return false;
        }
  
        if (has_guardian) {
          if (!guardian_name || !guardian_document || !guardian_birthdate) {
            return false;
          }
        }
  
        return true;
      },
      isSubmitButtonEnabled() {
        return this.step === 0
          ? this.isSubmitScheduleButtonEnable
          : this.isSubmitPatientButtonEnabled;
      },
      isSubmitScheduleButtonEnable() {
        if(!this.schedule.patient_id && !this.schedule.date && !this.schedule.time) {
          return false;
        } else {
          return true;
        }
      },
      selectedScheduleType() {
        if (!this.schedule.doctor_schedule_values_id || !this.availableValues.length) {
          return null;
        }
  
        return this.availableValues.find(
          (value) => value.id === this.schedule.doctor_schedule_values_id
        );
      },
  
      ...mapGetters(['clinicId', 'userId']),
    },
    methods: {
      patientSetter: PatientsService.getId,
      patientsGetter: PatientsService.getPatients,
      updateSchedule() {
        if (!this.attributes.procedure_id) {
          this.schedule.time = this.attributes.standard_time;
        } else {
          const procedure = this.availableProcedures.find((e) => e.id == this.attributes.procedure_id);
          this.schedule.time = procedure ? procedure.execution_time : '';
          if(procedure.is_session) {
            this.schedule.is_session = true;
          }
          
          if (procedure && procedure.instructions) {
            this.schedule.patient_instructions = procedure.instructions;
          } else {
            this.schedule.patient_instructions = '';
          }
        }
      },
      onSubmitNewPatient() {
        this.isSaving = true;
        this.errors = {};
  
        const {
          cpf,
          phone,
          name,
          has_guardian,
          guardian_name,
          guardian_birthdate,
          guardian_document,
        } = this.patient;
  
        const data = {
          name,
          guardian_name,
          has_guardian,
          guardian_birthdate,
          clinic_id: this.clinicId,
          cpf: cpf.replace(/\D/g, ''),
          phone: phone.replace(/\D/g, ''),
          guardian_document: guardian_document
            ? guardian_document.replace(/\D/g, '')
            : null,
        };
  
        PatientsService.store(data)
          .then(({ data }) => {
            this.$buefy.snackbar.open('Paciente salvo com sucesso.');
            this.schedule.patient_id = data.patient_id;
            this.step = 0;
          })
          .catch(({ response }) => {
            const { status, data } = response;
  
            if (status === 422) this.errors = JSON.parse(data.message);
            else
              this.$buefy.snackbar.open(
                data.message || 'Erro ao salvar paciente'
              );
          })
          .finally(() => {
            this.isSaving = false;
          });
      },
      loadAvailableScheduleValues() {
        SchedulesService.getTypeSchedules()
          .then(({ data }) => {
            this.availableValues = [];
  
            if (!data.length) {
              this.$buefy.snackbar.open({
                message:
                  'Por favor, antes de marcar uma consulta, cadastre seus valores',
              });
              return;
            }
  
            const scheduleTypes = [
              { id: 0, name: 'Presencial' },
              { id: 1, name: 'Teleconsulta' }
            ];
  
            scheduleTypes.forEach((type) => {
              const schedule = data.find((schedule) => schedule.id === type.id);
              if (schedule) {
                this.availableValues.push({
                  id: schedule.id,
                  name: type.name,
                });
              }
            });
  
            if (this.availableValues.length === 1) {
              this.schedule.doctor_schedule_values_id = this.availableValues[0].id;
            }
          });
      },
      loadAvailableServiceLocations() {
        const params = {
          clinic_id: this.clinicId,
          'filter[address_id]': this.schedule.address_id,
        };
  
        ServiceLocations.get(params).then(({ data }) => {
          const locations = data.map((location) => ({
            title: location.title,
            id: location.address_id,
            location_id: location.id,
            address: location.address,
          }));
  
          this.availableLocations = locations;
        });
      },
      loadAvailableProcedures() {
        this.procedureIsLoading = true;
  
        ProceduresService.get({
          clinic_id: this.clinicId,
          doctor_id: this.attributes.doctor_id,
          health_plan_id: this.attributes.health_plan_id,
        })
          .then(({ data }) => {
            const defaultProcedure = {
              id: '',
              name: 'Consulta',
              value: this.selectedScheduleType ? this.selectedScheduleType.value : null,
              noi: this.selectedScheduleType ? this.selectedScheduleType.noi : null,
            };
  
            this.availableProcedures = [defaultProcedure, ...data];
          })
          .finally(() => (
            this.updateSchedule(),
            this.procedureIsLoading = false
          ));
      },
      openCheckoutUrlDialog(url, isPix, chargeId = null) {
        this.$buefy.modal.open({
          parent: this,
          trapFocus: true,
          hasModalCard: true,
          component: LinkPaymentOptionVue,
          props: { url, isPix, chargeId },
          canCancel: !isPix,
          events: {
            close: () => this.$emit('reloadEvents'),
          },
        });
      },
      saveSchedule() {
        this.$emit('isLoading', true);
        this.errors = {};
  
        const { date, time} = this.schedule;
  
        const format = 'YYYY-MM-DD HH:mm:ss';
        const start = date ? moment(date).format(format) : '';
        const end = date ? moment(date).add(time, 'minute').format(format) : '';
  
        const data = {
          end,
          start,
          ...this.schedule,
          origin_request: 1,
          user_id: this.userId,
          clinic_id: this.clinicId,
          procedure_id: this.attributes.procedure_id,
          is_online: this.attributes.address_id == 9999 ? true : false,
          address_id: this.attributes.address_id,
          is_recurrence: this.is_recurrence,
          frequency_interval: this.frequency_interval,
          duration_in_days: this.duration_in_days,
          patient_ids: this.selectedPatients.map(p => p.id),
        };

        if (!data.patient_ids || data.patient_ids.length < 1) {
          this.$buefy.snackbar.open('Adicione pacientes para a sessão.');
          this.isSaving = false;
          return;
        }

        
        SessionService.store(data)
          .then(({ data }) => {
            !this.schedule.generate_checkout_url && this.$emit('reloadEvents');
  
            this.schedule.generate_checkout_url &&
              this.openCheckoutUrlDialog(
                data.pagarme_charge.resource_payment_content,
                false
              );
  
            this.$buefy.snackbar.open('Sessão agendada com sucesso!');
            this.isSaving = false;
          })
          .catch(({ response }) => {
            const { status, data } = response;
            let errorMessage = ' Erro ao tentar cadastrar consulta.';
            if (status === 422) {
              this.errors = data.message;
            } else {
              errorMessage = data.message ? data.message : errorMessage;
            }
  
            this.$buefy.snackbar.open(errorMessage);
            this.isSaving = false;
            this.$emit('isLoading', false);
          });
      },
      onSubmitSchedule() {
        if (this.schedule.has_voucher) {
          this.schedule.external_payment = true;
          this.saveSchedule();
        } else if (this.schedule.health_plan_id) {
          this.schedule.external_payment = true;
          this.schedule.payment_type = 3;
          this.saveSchedule();
        } else {
          this.schedule.external_payment = true;
          this.saveSchedule();
        }
      },
  
      validateDate() {
        if (this.schedule.fit) {
          this.onSubmitSchedule();
        } else {
          this.isSaving = true;
  
          const { date, time } = this.schedule;
          const format = 'YYYY-MM-DD HH:mm:ss';
          const start = date ? moment(date).format(format) : '';
          const end = date ? moment(date).add(time, 'minute').format(format) : '';
  
          SchedulesService.validateDate({
            end: end,
            start: start,
            clinic_id: this.clinicId,
            doctor_id: this.schedule.doctor_id,
            procedure_id: this.attributes.procedureId,
            health_plan_id: this.attributes.health_plan_id,
          })
            .then(() => {
              this.onSubmitSchedule();
            })
            .catch(({ response }) => {
              const { status, data } = response;
  
              if (status === 412) {
                this.$buefy.dialog.confirm({
                  message: data.message,
                  confirmText: 'Fechar',
                  type: 'is-primary',
                  canCancel: false,
                });
              }
  
              if (status === 428) {
                if (data.message.data.doctor_id) {
                  this.$buefy.dialog.confirm({
                    message: data.message.data.doctor_id,
                    confirmText: 'Fechar',
                    type: 'is-primary',
                    canCancel: false,
                  });
                }
                if (data.message.data.start) {
                  this.$buefy.dialog.confirm({
                    message: data.message.data.start,
                    confirmText: 'Fechar',
                    type: 'is-primary',
                    canCancel: false,
                  });
                }
  
                if (data.message.data.end) {
                  this.$buefy.dialog.confirm({
                    message: data.message.data.end,
                    confirmText: 'Fechar',
                    type: 'is-primary',
                    canCancel: false,
                  });
                }
  
                if (data.message.data.procedure_id) {
                  this.$buefy.dialog.confirm({
                    message: data.message.data.procedure_id,
                    confirmText: 'Fechar',
                    type: 'is-primary',
                    canCancel: false,
                  });
                }
              }
            })
            .finally(() => this.$emit('isLoading', false));
        }
      },
      defaultScheduleAttributes() {
        this.schedule = {
          ...this.schedule,
          doctor_schedule_values_id: null,
          fit: 0,
          is_pix: 0,
          installments: 1,
          generate_checkout_url: false,
          time: this.attributes.standard_time,
          doctor_id: this.attributes.doctor_id,
          address_id: this.attributes.address?.id,
          health_plan_id: this.attributes.health_plan_id,
          date: this.attributes.start,
          procedure_id: this.attributes.procedureId,
          is_session: false
        };
        
        this.scheduleFitDate = this.attributes.start.toDate();
      },
      checkActiveVoucher() {
        this.isLoading = true;
        const params = {
          patient_id: this.schedule.patient_id,
          doctor_id: this.schedule.doctor_id,
        };
        VouchersService.getActiveVoucher(params)
          .then(({ data }) => {
            this.activeVoucher = data;
          })
          .finally(() => (this.isLoading = false));
      },
      onSubmit() {
        this.step === 0 ? this.validateDate() : this.onSubmitNewPatient();
      },
      addPatient() {
        if (!this.schedule.patient_id) {
          this.$buefy.snackbar.open({
                    message: 'Paciente já adicionado à sessão.',
                });
          return;      
        }

        try {
            const existingPatient = this.selectedPatients.find(
                patient => patient.id === this.schedule.patient_id
            );

            if (!existingPatient) {
                this.patientSetter(this.schedule.patient_id)
                    .then(({ data: patient }) => {
                        this.selectedPatients.push(patient); 
                        this.schedule.patient_id = null; 
                    })
                    .catch(() => {
                      this.$buefy.snackbar.open({
                    message: 'Erro ao buscar o paciente.',
                });
                    });
            } else {
                this.$buefy.snackbar.open({
                    message: 'Paciente já adicionado à sessão.',
                });
            }
        } catch (error) {
          this.$buefy.snackbar.open({
                    message: 'Paciente já adicionado à sessão.',
                });
        }
    },
    removePatient(patientId) {
        this.selectedPatients = this.selectedPatients.filter(patient => patient.id !== patientId);
    }
    },
    mounted() {
      this.$emit('isLoading', false);
      this.loadAvailableProcedures();
    },
    beforeMount() {
      this.defaultScheduleAttributes();
      this.loadAvailableScheduleValues();
      this.loadAvailableServiceLocations();
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .new-schedule-view {
    padding: 16px;
  
    &__details {
      display: flex;
      gap: 10px;
      flex-direction: column;
  
      &__header {
        background-color: var(--color-secondary);
  
        display: flex;
        justify-content: space-between;
  
        border-radius: 8px 8px 0 0;
        padding: 8px;
  
        height: 80px;
  
        h1,
        h2,
        span {
          color: #fff;
        }
  
        h1 {
          font-size: 1.2rem;
          font-weight: 600;
        }
  
        h2 {
          font-weight: 500;
          font-size: 1.4rem;
        }
  
        div:last-child span:hover {
          cursor: pointer;
        }
      }
  
      &__form {
        padding-right: 10px;
        height: 560px;
        overflow-y: auto;
  
        &__input {
          margin-bottom: 10px;
          padding: 10px;
  
          background-color: rgba(114, 135, 152, 0.1);
  
          border-radius: 6px;
  
          min-height: 60px;
        }
  
        &__input.patient {
          display: flex;
          gap: 5px;
          align-items: center;
  
          .field {
            width: 90% !important;
          }
  
          .new-patient-button {
            height: 40px;
            margin-top: 20px;
            border-radius: 4px;
          }
        }
  
        &__input.address p {
          font-size: 1.1rem;
          font-weight: 700;
  
          color: #728798;
  
          span {
            font-weight: 400;
            text-decoration-line: underline;
          }
        }
      }

      &__selected-patients {
        margin-bottom: 10px;
          padding: 10px;
  
          background-color: rgba(114, 135, 152, 0.1);
  
          border-radius: 6px;
  
          min-height: 60px;
        &-list {
          padding: 10px;
          background-color: rgb(4, 8, 241);
          border-radius: 6px;
        }
        &__patient-item {
          padding: 10px;
          border-bottom: 1px solid #ff7677;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      } 
      &__save-schedule {
        position: absolute;
        bottom: 16px;
        left: 16px;
        right: 16px;
      }
    }
  }
  </style>
  