import * as AuthActions from '@/store/actions/auth';
import STORAGE from '@/constants/storage.constant';

import Api from '@/services/api.service';
import AuthService from '@/services/auth.service';

import jwt_decode from 'jwt-decode';

const state = {
  loading: true,
  error: false,
  token: null,
  info: {},
};

const getters = {
  userId: (state) => state.info.user_id,
  clinicId: (state) => state.info.clinic_id,
  doctorId: (state) => state.info.doctor_id,
  doctorType: (state) => state.info.doctor_type,
  isAdmin: (state) => state.info.is_admin,
  isAuthenticated: (state) => !!state.token,
  clinics: (state) => state.info.clinics,
  clinicName: (state) => {
    const idx = state.info.clinics.findIndex(
      (clinic) => clinic.id == state.info.clinic_id
    );
    return state.info.clinics[idx].name;
  },
  userType: (state) => state.info.type,
  displayName: (state) => state.info.display_name.split(' ')[0],
  userName: (state) => state.info.display_name,
  hasPagarmeAccount: (state) => state.info.has_pagarme_account,
  pagarmeAccountInfo: (state) => state.info.pagarme_account_infos,
  permissions: (state) => jwt_decode(state.token)['permissions'],
  role: (state) => jwt_decode(state.token)['roles'][0]['name'],
  clinicIsComplete: (state) => state.info.clinic_is_complete,
  admIsComplete: (state) => state.info.adm_is_complete,
  addressIsComplete: (state) => state.info.address_is_complete,
  bankIsComplete: (state) => state.info.bank_is_complete,
  memedToken: (state) => state.info.memed_token,
  hasCrm: (state) => state.has_crm,
};

const getTokenData = (token) => {
  const { info } = jwt_decode(token);
  const {
    clinic_id,
    user_id,
    doctor_id,
    is_admin,
    display_name,
    type,
    clinic_is_complete,
    adm_is_complete,
    address_is_complete,
    bank_is_complete,
    has_pagarme_account,
    pagarme_account_infos,
    clinics,
    memed_token,
    doctor_type,
    has_crm,
  } = info;

  return {
    clinic_id,
    user_id,
    doctor_id,
    is_admin,
    display_name,
    type,
    clinic_is_complete,
    adm_is_complete,
    address_is_complete,
    bank_is_complete,
    has_pagarme_account,
    pagarme_account_infos,
    clinics,
    memed_token,
    doctor_type,
    has_crm,
  };
};

const actions = {
  [AuthActions.SET_DEFAULT_CLINIC]: ({ commit }, value) => {
    commit(AuthActions.SET_DEFAULT_CLINIC, value);
  },
  [AuthActions.SET_IS_COMPLETE]: ({ commit }, {field, value = true}) => {
    commit(AuthActions.SET_IS_COMPLETE, {field, value});
  },
  [AuthActions.SET_GATEWAY_ACCOUNT_INFOS]: ({ commit }, infos) => {
    commit(AuthActions.SET_GATEWAY_ACCOUNT_INFOS, infos);
  },
  [AuthActions.AUTH_TOKEN]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(AuthActions.AUTH_TOKEN);

      const token = localStorage.getItem(STORAGE.AUTH_TOKEN);
      Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      if (!token) {
        commit(AuthActions.AUTH_TOKEN_ERROR);
        reject('No token.');
        return;
      }

      AuthService.refresh()
        .then(({ data }) => {
          const { access_token: newToken } = data;
          localStorage.clear();
          commit(AuthActions.AUTH_TOKEN_SUCCESS, newToken);
          localStorage.setItem(STORAGE.AUTH_TOKEN, newToken);
          resolve(token);
        })
        .catch(() => {
          localStorage.removeItem(STORAGE.AUTH_TOKEN);
          commit(AuthActions.AUTH_TOKEN_ERROR);
          commit(AuthActions.AUTH_LOGOUT);
          reject('Token expired.');
        });
    });
  },
  [AuthActions.AUTH_REQUEST]: ({ commit }, loginData) => {
    return new Promise((resolve, reject) => {
      commit(AuthActions.AUTH_REQUEST);

      AuthService.login(loginData)
        .then(({ data }) => {
          const { access_token } = data;

          localStorage.setItem(STORAGE.AUTH_TOKEN, access_token);
          commit(AuthActions.AUTH_REQUEST_SUCCESS, access_token);
          resolve(access_token);
        })
        .catch((err) => {
          commit(AuthActions.AUTH_REQUEST_ERROR, err);
          localStorage.removeItem(STORAGE.AUTH_TOKEN);
          reject(err);
        });
    });
  },
  [AuthActions.AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(AuthActions.AUTH_LOGOUT);
      localStorage.clear();

      resolve();
    });
  },
};

const mutations = {
  [AuthActions.SET_DEFAULT_CLINIC]: (state, value) => {
    state.info.clinic_id = value;
  },
  [AuthActions.SET_IS_COMPLETE]: (state, {field, value}) => {
    state.info[field] = value;
  },
  [AuthActions.SET_GATEWAY_ACCOUNT_INFOS]: (state, infos) => {
    state.info.pagarme_account_infos = infos;
  },
  [AuthActions.AUTH_TOKEN]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [AuthActions.AUTH_TOKEN_SUCCESS]: (state, token) => {
    state.loading = false;
    state.token = token;
    state.info = getTokenData(token);
    Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },
  [AuthActions.AUTH_TOKEN_ERROR]: (state) => {
    state.loading = false;
    state.error = true;
    state.token = null;
  },
  [AuthActions.AUTH_REQUEST]: (state) => {
    state.error = false;
  },
  [AuthActions.AUTH_REQUEST_SUCCESS]: (state, token) => {
    state.token = token;
    state.info = getTokenData(token);
    Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },
  [AuthActions.AUTH_REQUEST_ERROR]: (state) => {
    state.error = true;
    state.token = null;
  },
  [AuthActions.AUTH_LOGOUT]: (state) => {
    state.token = null;
    // Movidesk.hideMovidesk();
    Api.defaults.headers.common['Authorization'] = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
