<template>
    <section class="schedule-view">
      <div class="schedule-view__header">
        <div>
            <h1>Sessão</h1>
            <h2>
              {{ attributes.start | date('DD/MM/YYYY HH:mm') }}
            </h2>
        </div>

        <div @click="() => (step === 0 ? $emit('close') : (step = 0))">
            <b-icon :icon="step === 0 ? 'times' : 'arrow-left'"></b-icon>
          </div>
      </div>
      <div class="schedule-view__details" v-if="isStep(0)">
        <div class="form-inline">
          <div class="d-flex align-items-center">
            <app-autocomplete 
                is-required 
                field="name" 
                label="Paciente" 
                ref="patientSelector" 
                :setter="patientSetter"
                :getter="patientsGetter" 
                :errors="patient.id" 
                v-model="patient.id"
                placeholder="Nome ou o CPF do paciente">
            </app-autocomplete>
            
            <b-tooltip label="Adicionar Paciente" type="is-dark" position="">
              <b-button @click="addPatient(patient.id)" type="is-primary" class="add-patient-button mt-4 ml-2">
                <b-icon icon="plus" size="is-small"></b-icon>
              </b-button>
            </b-tooltip>
          </div>  

          <b-tooltip label="Cadastrar novo paciente">
            <b-button @click="changeStep()" type="is-text" class="mt-4 mr-4">
              <b-icon icon="user-plus" size="is-small"></b-icon>
            </b-button>
          </b-tooltip>
        </div>
  
        <div class="schedule-view__details__infos custom-scroll">

          <div class="schedule-view__details__members-list">
          <h2>Lista de Participantes</h2>
          <ul>
            <li v-for="member in members" :key="member.id" class="schedule-view__details__members-list__member-item">
              <span>{{ member.patient.name }}</span>
              <b-button @click="removeMember(member.id)" type="" size="is-small">
                <b-icon class="remove-member-icon" icon="trash"></b-icon>
              </b-button>
            </li>
          </ul>
        </div>

        <div class="schedule-view__details__information">
            <div>
              <b-icon class="icon-3" icon="users"></b-icon>
              <p>
                <b>Participantes:</b>
                {{ schedule.members.length }} / {{ schedule.procedure.quantity_members_accepted }}
              </p>
            </div>
        </div>

        <div class="schedule-view__details__information">
          <div>
            <b-icon class="icon-1" icon="hospital-user"></b-icon>
            <p><b>Atendimento</b>: {{ schedule.is_online ? 'Online' : 'Presencial' }}</p>
          </div>
        </div>

        <div class="schedule-view__details__information">
          <div>
            <b-icon class="icon-2" icon="notes-medical"></b-icon>
            <p>
              <b>Procedimento:</b>
              {{ schedule.procedure_id ? schedule.procedure.name : 'Consulta' }}
            </p>
          </div>
        </div>

        <div class="schedule-view__details__information">
          <div>
            <b-icon class="icon-5" icon="clock"></b-icon>
            <p>
              <b>Tempo de Duração:</b>
              {{ schedule.procedure && schedule.procedure.execution_time ? schedule.procedure.execution_time + ' min' : 'Não especificado' }}
            </p>
          </div>
        </div>

        <div class="schedule-view__details__information" v-if="schedule.schedule_recurrence">
          <div>
            <b-icon class="icon-4" icon="calendar"></b-icon>
            <p>
              <b>Data de Início:</b> {{ schedule.start | date('DD/MM/YYYY') }}
            </p>
            <p v-if="sessionEndDate">
              <b>Data de Término:</b> {{ sessionEndDate }}
            </p>
          </div>
        </div>
  
          <div
            v-if="schedule.observation"
            class="schedule-view__details__information"
          >
            <div>
              <b-icon class="icon-6" icon="info-circle"></b-icon>
              <p><b>observação do atendimento</b>: {{ schedule.observation }}</p>
            </div>
          </div>
  
          <div
            v-if="schedule.patient_instructions"
            class="schedule-view__details__information"
          >
            <div>
              <b-icon class="icon-7" icon="receipt"></b-icon>
              <p>
                <b>Instrução para o paciente</b>:
                {{ schedule.patient_instructions }}
              </p>
            </div>
          </div>
        </div>
        <div class="schedule-view__details__go-to-schedule">
            <b-button expanded type="is-danger" @click="cancelSession">
              Cancelar Sessão
            </b-button>
        </div>
      </div>

      <div v-if="isStep(1)" class="new-schedule-view__details__form custom-scroll">
          <div class="new-schedule-view__details__form__input">
            <app-input is-required label="Nome" :errors="errors.name" v-model="patient.name"
              placeholder="Ex: João campos" />
          </div>
  
          <div class="new-schedule-view__details__form__input">
            <app-input is-required label="CPF" :mask="MASKS.cpf" v-model="patient.cpf" :errors="errors.cpf"
              placeholder="111.222.333-55" />
          </div>
          <div class="new-schedule-view__details__form__input">
            <app-input type="tel" is-required label="Celular" :errors="errors.phone" v-model="patient.phone"
              v-mask="'(##) #####-####'" placeholder="(99) 99999-9999"></app-input>
          </div>
  
          <div class="new-schedule-view__details__form__input">
            <b-checkbox style="margin-top: 10px" v-model="patient.has_guardian">
              Menor de idade ou incapaz?
            </b-checkbox>
          </div>
  
          <div v-if="patient.has_guardian">
            <div class="new-schedule-view__details__form__input">
              <app-input is-required type="text" label="Nome do responsável" placeholder="Ex: Márcio campos"
                :errors="errors.guardian_name" v-model="patient.guardian_name" />
            </div>
  
            <div class="new-schedule-view__details__form__input">
              <app-input is-required type="tel" v-mask="'###.###.###-##'" label="CPF do responsável"
                placeholder="111.222.333-55" :errors="errors.guardian_document" v-model="patient.guardian_document" />
            </div>
  
            <div class="new-schedule-view__details__form__input">
              <b-field class="required" label="Data de nascimento do responsável"
                :type="errors && errors.guardian_birthdate ? 'is-danger' : ''" :message="errors && errors.guardian_birthdate
                  ? errors.guardian_birthdate
                  : ''
                  ">
                <b-datepicker :locale="'pt-BR'" position="is-top-right" :years-range="[-100, 0]"
                  placeholder="Selecionar data" :date-formatter="bDateFormatter" v-model="patient.guardian_birthdate">
                </b-datepicker>
              </b-field>
            </div>
          </div>
        </div>
  
        <div>
          <div class="new-schedule-view__details__save-schedule" v-if="step===1">
            <b-button expanded type="is-dark" :loading="isSaving" @click="onSubmit" :disabled="!isSubmitButtonEnabled">
              Cadastrar Paciente
            </b-button>
          </div>
        </div>
    </section>
  </template>
  
  <script>
  
  import SchedulesService from '@/services/schedules.service';
  import SessionService from '@/services/session.service';
  import PatientsService from '@/services/patients.service';
  import SCHEDULE_STATUS from '@/enums/ScheduleStatusEnum';
  import AppAutocomplete from '@/components/inputs/Autocomplete.vue';
  import MASKS from '@/constants/masks.constant';
  import { mapGetters } from 'vuex';
  
  export default {
    name: 'session-detail',
    components: { AppAutocomplete },
    props: {
      scheduleId: {
        type: Number,
        default: () => null,
      },
      attributes: {
        type: Object,
        default: null,
      },
    },
    data: () => ({
      step: 0,
      schedule: {
        patient: { name: '', cpf: '', birthday: '' },
        payment: { name: '' },
        procedure: { name: '' },
        health_plan: { name: '' },
      },
      patient: {
        id: null,
        name: null,
        cpf: null,
        phone: null,
        has_guardian: false,
        guardian_name: null,
        guardian_document: null,
        guardian_birthdate: null,
      },
      availableStatus: [],
      scheduleStatus: null,
      SCHEDULE_STATUS: SCHEDULE_STATUS,
      members: [],
      errors: {},
      MASKS: MASKS,
      isSaving: false,
    }),
    watch: {
      scheduleId(newId) {
      if (newId) {
        this.loadSchedule();
      }
    },
    },
    computed: {
      sessionEndDate() {
        if (this.schedule.start && this.schedule.schedule_recurrence) {
          const startDate = new Date(this.schedule.start);
          const durationDays = this.schedule.schedule_recurrence.duration_in_days;
          const endDate = new Date(startDate);
          endDate.setDate(startDate.getDate() + durationDays);

          return endDate.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });
        }
        return null;
      },
      isSubmitButtonEnabled() {
        const {
          name,
          cpf,
          phone,
          has_guardian,
          guardian_name,
          guardian_document,
          guardian_birthdate,
        } = this.patient;
  
        if (!name || !cpf || !phone || phone.length < 15) {
          return false;
        }
  
        if (has_guardian) {
          if (!guardian_name || !guardian_document || !guardian_birthdate) {
            return false;
          }
        }
  
        return true;
      },
      ...mapGetters(['clinicId', 'userId']),
    },
    methods: {
      patientSetter: PatientsService.getId,
      patientsGetter: PatientsService.getPatients,
      changeStep() {
        this.step = this.step === 0 ? 1 : 0;
      },

      isStep(value) {
        return this.step === value;
      },
      onSubmit() {
        this.isSaving = true;
        this.errors = {};
  
        const {
          cpf,
          phone,
          name,
          has_guardian,
          guardian_name,
          guardian_birthdate,
          guardian_document,
        } = this.patient;
  
        const data = {
          name,
          guardian_name,
          has_guardian,
          guardian_birthdate,
          clinic_id: this.clinicId,
          cpf: cpf.replace(/\D/g, ''),
          phone: phone.replace(/\D/g, ''),
          guardian_document: guardian_document
            ? guardian_document.replace(/\D/g, '')
            : null,
        };
  
        PatientsService.store(data)
          .then(({ data }) => {
            this.$buefy.snackbar.open('Paciente salvo com sucesso.');
            this.schedule.patient_id = data.patient_id;
            this.step = 0;
            this.isSaving = false;
          })
          .catch(({ response }) => {
            const { status, data } = response;
            this.isSaving = true;
            if (status === 422) this.errors = JSON.parse(data.message);
            else
              this.$buefy.snackbar.open(
                data.message || 'Erro ao salvar paciente'
              );
          })
          .finally(() => {
            this.isSaving = false;
          });
      },
      cancelSession() {
        this.$emit('isLoading', true);
        SessionService.cancelSession(this.schedule.id)
          .then(() => {
            this.$emit('reloadEvents');
            this.schedule.status = SCHEDULE_STATUS.CANCELED;
            this.$buefy.snackbar.open('Consulta cancelada com sucesso!');
          })
          .catch(({ response }) => {
            let errorMessage = 'Erro inesperado ao cancelar consulta!';
  
            if (response.status == 403) {
              const { message } = response.data;
              errorMessage = message.content;
            }
  
            this.$buefy.snackbar.open({
              type: 'is-danger',
              duration: 6500,
              message: errorMessage,
            });
          })
          .finally(() => this.$emit('isLoading', false));
      },
      addPatient(patient_id) {
        if(!patient_id || patient_id.length === 0) {
          this.$buefy.snackbar.open('Selecione um paciente');
          return false;
        }
        this.$emit('isLoading', true);
          let payload = {
            patient_id: patient_id,
            session_id: this.schedule.id
          }
          SessionService.addMember(payload).then(() => {
            this.loadSchedule();
          })
      },
      removeMember(memberId) {
        this.$emit('isLoading', true);
        SessionService.removeMember(this.schedule.id,memberId)
          .then(() => {
            this.members = this.members.filter(member => member.id !== memberId);
            this.$buefy.snackbar.open('Membro removido com sucesso!');
            this.$emit('isLoading', false);
          })
          .catch(() => this.$buefy.snackbar.open('Erro ao remover o membro'))
          .finally(() => this.$emit('isLoading', false));
      },
      confirmSchedule() {
        this.$emit('isLoading', true);
        SchedulesService.confirmSchedule(this.scheduleId)
          .then(() => {
            this.schedule.status = SCHEDULE_STATUS.CONFIRMED;
          })
          .finally(() => this.$emit('isLoading', false));
      },
      onConfirmScheduleClick(oldStatus) {
        this.$buefy.dialog.confirm({
          hasIcon: true,
          type: 'is-warning',
          cancelText: 'Cancelar',
          confirmText: 'Confirmar',
          title: 'Confirmação de agenda',
          onConfirm: () => this.confirmSchedule(),
          onCancel: () => (this.schedule.status = oldStatus),
          message: 'Tem certeza que deseja <b>confirmar</b> este agendamento?',
        });
      },
      loadStatus() {
        SchedulesService.getStatus().then(({ data }) => {
          this.availableStatus = Object.values(data);
        });
      },
      loadSchedule() {
        this.$emit('isLoading', true);
  
        SessionService.getId(this.scheduleId)
          .then(({ data }) => {
            this.schedule = data;
            this.scheduleStatus = this.schedule.status;
            this.members = data.members;
          })
          .catch(() => this.$buefy.snackbar.open('Erro ao consultar consulta'))
          .finally(() => this.$emit('isLoading', false));
      },
      cancelSchedule(data, oldStatus) {
        this.$emit('isLoading', true);
  
        SchedulesService.cancelSchedule(this.scheduleId, { ...data })
          .then(() => {
            this.$emit('reloadEvents');
            this.schedule.status = SCHEDULE_STATUS.CANCELED;
            this.$buefy.snackbar.open('Consulta cancelada com sucesso!');
          })
          .catch(({ response }) => {
            let errorMessage = 'Erro inesperado ao cancelar consulta!';
  
            if (response.status == 403) {
              const { message } = response.data;
              errorMessage = message.content;
            }
  
            this.$buefy.snackbar.open({
              type: 'is-danger',
              duration: 6500,
              message: errorMessage,
            });
  
            this.schedule.status = oldStatus;
          })
          .finally(() => this.$emit('isLoading', false));
      },
    },
    beforeMount() {
      this.loadStatus();
      this.loadSchedule();
    },
  };
  </script>
  
  <style lang="scss" scoped>
.form-inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}


.form-inline .autocomplete-field {
  flex: 1;
  min-width: 200px;
}


.form-inline .add-patient-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.form-inline .add-patient-button::after {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
  .form-inline {
    flex-direction: column;
    align-items: stretch;
  }

  .form-inline .autocomplete-field,
  .form-inline .add-patient-button {
    width: 100%;
    margin-top: 10px;
  }
}

  .remove-member-icon {
    border-radius: 5px;
  }

  .schedule-view {
    padding: 16px;
  
    &__header {
      background-color: var(--color-secondary);
  
      display: flex;
      justify-content: space-between;
  
      border-radius: 8px 8px 0 0;
      padding: 8px;
  
      height: 80px;
  
      h1,
      h2,
      span {
        color: #fff;
      }
  
      h1 {
        font-size: 1.6rem;
        font-weight: 600;
      }
  
      h2 {
        font-weight: 500;
        font-size: 0.9rem;
      }
  
      div:last-child span:hover {
        cursor: pointer;
      }
  
      margin-bottom: 10px;
    }
  
    &__details {
      display: flex;
      gap: 10px;
      flex-direction: column;
  
      &__infos {
        height: 440px;
        overflow-y: auto;
        padding-right: 5px;
      }
  
      &__payment,
      &__status,
      &__information {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        background-color: rgba(114, 135, 152, 0.1);
  
        border-radius: 6px;
  
        min-height: 60px;
  
        button {
          min-width: 40%;
        }
      }
  
      &__payment {
        h3 {
          font-size: 1.6rem;
          font-weight: 700;
          color: #728798;
        }
  
        small {
          font-size: 0.9rem;
          color: #728798;
          span {
            font-weight: 600;
          }
        }
      }
  
      &__payment.confirmed {
        background-color: rgba(73, 198, 142, 0.2);
  
        h3 {
          color: #49c68d;
        }
      }
  
      &__information {
        margin-bottom: 10px;
        div {
          display: flex;
          align-items: center;
  
          gap: 10px;
  
          text-transform: capitalize;
  
          color: #728798;
          padding: 5px;
        }
        .icon-1 {
          color: #419f6a;
        }
        .icon-2 {
          color: #317fec;
        }
        .icon-3 {
          color: #fb921a;
        }
        .icon-4 {
          color: #b13f22;
        }
        .icon-5 {
          color: #fb5858;
        }
        .icon-6 {
          color: #6616b5;
        }
  
        .icon-7 {
          color: #cc199c;
        }
      }
  
      &__information.status {
        .control {
          width: 50%;
        }
      }
  
      &__go-to-schedule {
        position: absolute;
        bottom: 16px;
        left: 16px;
        right: 16px;
      }

      &__members-list {
        padding: 10px;
        background-color: rgba(114, 135, 152, 0.1);
        border-radius: 6px;
        margin-bottom: 10px;

        &__member-item {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 5px;
          margin-bottom: 10px;

          span {
            font-weight: 600;
            color: #728798;
          }
        }
      }
    }
  }

  .new-schedule-view {
    padding: 16px;
  
    &__details {
      display: flex;
      gap: 10px;
      flex-direction: column;
  
      &__header {
        background-color: var(--color-secondary);
        display: flex;
        justify-content: space-between;
        border-radius: 8px 8px 0 0;
        padding: 8px;
        height: 80px;
  
        h1,
        h2,
        span {
          color: #fff;
        }
  
        h1 {
          font-size: 1.2rem;
          font-weight: 600;
        }
  
        h2 {
          font-weight: 500;
          font-size: 1.4rem;
        }
  
        div:last-child span:hover {
          cursor: pointer;
        }
      }
  
      &__form {
        padding-right: 10px;
        height: 560px;
        overflow-y: auto;
  
        &__input {
          margin-bottom: 10px;
          padding: 10px;
          background-color: rgba(114, 135, 152, 0.1);
          border-radius: 6px;
          min-height: 60px;
        }
  
        &__input.patient {
          display: flex;
          gap: 5px;
          align-items: center;
  
          .field {
            width: 90% !important;
          }
  
          .new-patient-button {
            height: 40px;
            margin-top: 50px;
            border-radius: 5px;
            margin-left: 10px;
          }
        }
  
        &__input.address p {
          font-size: 1.1rem;
          font-weight: 700;
          color: #728798;
  
          span {
            font-weight: 400;
            text-decoration-line: underline;
          }
        }
      }
      &__save-schedule {
        position: absolute;
        bottom: 16px;
        left: 16px;
        right: 16px;
      }
    }
  }
  </style>
  