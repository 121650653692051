import Api from './api.service';
const ENDPOINT = 'schedules-recurrence';

export default {
  get: (params = {}) =>
    Api.get(`${ENDPOINT}`, {
      params,
    }),
  getId: (id, params = {}) =>
    Api.get(`${ENDPOINT}/${id}`, {
      params,
    }),
  store: (data) => Api.post(`${ENDPOINT}/generate-schedule`, data),
  deleteRecurrence: (data = {}) =>
    Api.delete(`${ENDPOINT}/delete-recurrence`, { params: data }),
  preview: {
    store: (data) => Api.post(`${ENDPOINT}/generate-preview-schedule`, data),
    delete: (data) => Api.post(`${ENDPOINT}/delete-preview-schedule`, data),
  }
};
