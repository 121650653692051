<template>
  <div class="schedule_container_view">
    <b-loading :is-full-page="false" v-model="isLoading" />
    <SessionDetail
      v-if="!newSchedule && isSessionSchedule"
      :scheduleId="schedule.id"
      :attributes="newScheduleAttributes"
      @close="() => $emit('close')"
      @reloadEvents="() => $emit('reloadEvents')"
      @isLoading="(loading) => (isLoading = loading)"
    />

    <ScheduleDetail
      v-else-if="!newSchedule && !isSessionSchedule"
      :scheduleId="schedule.id"
      @close="() => $emit('close')"
      @reloadEvents="() => $emit('reloadEvents')"
      @isLoading="(loading) => (isLoading = loading)"
    />

    <NewSession
      v-else-if="newSchedule && isSessionNew"
      :attributes="newScheduleAttributes"
      @close="() => $emit('close')"
      @reloadEvents="() => $emit('reloadEvents')"
      @isLoading="(loading) => (isLoading = loading)"
    />

    <NewSchedule
      v-else
      :attributes="newScheduleAttributes"
      @close="() => $emit('close')"
      @reloadEvents="() => $emit('reloadEvents')"
      @isLoading="(loading) => (isLoading = loading)"
    />
  </div>
</template>

<script>
import ScheduleDetail from '@/components/schedule/ScheduleDetail.vue';
import SessionDetail from '@/components/schedule/SessionDetail.vue';
import NewSchedule from '@/components/schedule/NewSchedule.vue';
import NewSession from '@/components/schedule/NewSession.vue';

export default {
  name: 'schedule-container',
  components: {
    ScheduleDetail,
    SessionDetail,
    NewSchedule,
    NewSession
  },
  props: {
    newSchedule: {
      type: Object,
      default: () => null,
    },
    schedule: {
      type: [Number, Object],
      default: () => null,
    },
    doctorId: {
      type: Number,
      default: () => null,
    },
    healthPlanId: {
      type: Number,
      default: () => null,
    },
    procedureId: {
      type: Number,
      default: () => null,
    },
    addressId: {
      type: Number,
      default: () => null,
    },
  },
  data: () => ({
    isLoading: true,
  }),
  computed: {
    isSessionNew() {
      return this.newSchedule && this.newSchedule.is_session === true;
    },
    isSessionSchedule() {
      return this.schedule && this.schedule.is_session === true;
    },
    newScheduleAttributes() {
      return {
        ...this.newSchedule,
        doctor_id: this.doctorId,
        health_plan_id: this.healthPlanId,
        procedure_id: this.procedureId,
        address_id: this.addressId,
      };
    },
  },
};
</script>


<style lang="scss" scope>
.schedule_container_view {
  height: inherit;
  position: relative;

  width: 75%;
  background-color: #fff;
  box-shadow: 0 0.2em 1em -0.115em rgba(0, 0, 0, 0.1),
    0 0px 0 1px rgba(0, 0, 0, 0.02);
  border-radius: 6px;
}
</style>
