import Api from './api.service';

export default {
  get: (params = {}) => Api.get('session',{params}),
  getId: (id) => Api.get(`session/${id}`),
  store: (session) => Api.post('session', session),
  update: (id, data) => Api.put(`session/${id}`, data),
  destroy: (id) => Api.delete(`session/${id}`),
  getOriginRequest: () => Api.get('session/get-origin-request'),
  addMembers: (data) => Api.post('session/add-members', data),
  removeMember: (session_id, member_id) => Api.delete(`session/remove-member/${session_id}/${member_id}`),
  listMembers: (id) => Api.get(`session/list-members/${id}`),
  addMember: (id) => Api.post(`session/add-member`, id),
  cancelSession: (id) => Api.delete(`session/${id}`)
};
